import * as Sentry from '@sentry/vue';
import { fullStoryIntegration } from '@sentry/fullstory';
import { FullStory } from '@fullstory/browser';

const severity = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DEBUG: 'debug',
};

export default {
    init({ app, router }) {
        try {
            Sentry.init({
                app,
                dsn: import.meta.env.VUE_APP_SENTRY_KEY,
                environment: window.__KEAP__.VUE_APP_ENV,
                tracesSampleRate: 0.05,
                ignoreErrors: [
                    /^Request failed with status code \d{3}$/,
                    /Object Not Found Matching Id:\d+/,
                    'Network error: Failed to fetch',
                    'ChunkLoadError',
                    'JWT is expired',
                    'JWT is not present in the auth session',
                    'Redirected when going from', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L13
                    'Avoided redundant navigation', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L24
                    'Navigation cancelled', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L33
                    'Navigation aborted', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L44
                ],
                integrations: [
                    fullStoryIntegration(import.meta.env.VUE_APP_SENTRY_ORG, { client: FullStory }),
                    Sentry.browserTracingIntegration({ router }),
                ],
                release: import.meta.env.SENTRY_RELEASE,
                denyUrls: [
                    // Ignore Pendo and FullStory errors
                    /cdn\.pendo\.io/i,
                    /rs\.fullstory\.com/i,
                ],
            });
        } catch (e) {
            // swallow sentry failures
        }
    },

    setUser({ casId }) {
        try {
            if (casId) {
                Sentry.getCurrentScope().setUser({
                    id: casId,
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    log(message, data = {}, level = severity.WARNING) {
        try {
            if (import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled') {
                Sentry.withScope((scope) => {
                    scope.setLevel(level);

                    Object.keys(data).forEach((key) => {
                        scope.setExtra(key, data[key]);
                    });

                    Sentry.captureMessage(message, { level });
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    captureException(error) {
        try {
            if (import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled') {
                Sentry.captureException(error);
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    severity,
};
