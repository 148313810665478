import { createI18n as createI18nBase } from 'vue-i18n';
import moment from 'moment';
import numberFormats from './numberFormats.json';
import defaultMomentLocale from './defaultMomentLocale.json';

export const createI18n = (options = {}) => {
    const i18n = createI18nBase({
        legacy: true,
        allowComposition: true,
        locale: 'en-us',
        fallbackLocale: 'en-us',
        numberFormats,
        silentTranslationWarn: true,
        silentFallbackWarn: true,
        missing: () => {},
        ...options,
    });

    moment.updateLocale('en', defaultMomentLocale);

    const formatNumber = i18n.global.n;
    const translate = i18n.global.t;
    const translateConditional = i18n.global.tc;

    const n = (value, ...args) => {
        try {
            return formatNumber(Number(value), ...args);
        } catch {
            return Number.NaN.toString();
        }
    };

    const t = (...args) => {
        try {
            return translate(...args);
        } catch {
            return '';
        }
    };

    const tc = (...args) => {
        try {
            return translateConditional(...args);
        } catch {
            return '';
        }
    };

    i18n.global.n = n;
    i18n.global.t = t;
    i18n.global.tc = tc;
    i18n.t = i18n.global.t;
    i18n.tc = i18n.global.tc;
    i18n.te = i18n.global.te;

    return i18n;
};
