import { inject } from 'vue';
import { FullStory, init } from '@fullstory/browser';

export const FULL_STORY_KEY = 'fullstory';

function noop() {}

export const createFullStory = ({ orgId, devMode, enabled }) => {
    if (enabled) {
        init({ orgId, devMode });
    }

    const fs = {
        identify: enabled ? (uid, properties) => FullStory('setIdentity', { uid, properties }) : noop,
        event: enabled ? (name, properties) => FullStory('trackEvent', { name, properties }) : noop,
        FullStory: enabled ? FullStory : noop,
        install(app) {
            app.provide(FULL_STORY_KEY, fs);
        },
    };

    return fs;
};

export const useFullStory = () => {
    return inject(FULL_STORY_KEY);
};
