export const CREDIT_CARD_EXPIRATION_MAX_LENGTH = 5;
export const CREDIT_CARD_NUMBER_MAX_LENGTH = 19;

export const DISCOUNT_TYPES = {
    percent: 'PERCENT',
    amount: 'AMOUNT',
};

export const DEPOSIT_TYPES = {
    percent: 'percent',
    dollar: 'dollar',
};

export const PAYMENT_ACCOUNT_TYPE = {
    WEPAY: 'WePay',
    AUTHORIZE: 'Authorize',
    PAYPAL: 'PayPal',
    STRIPE: 'Stripe',
    NEXUS: 'Nexus',
    EWAY: 'EWay',
    RAINFOREST: 'Rainforest',
};

export const PAYMENT_TYPES = {
    PAY_PAL: 'payPal',
    CREDIT_CARD: 'creditCard',
    TOKEN: 'token',
};

export const orderStatus = {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    SENT: 'SENT',
    VIEWED: 'VIEWED',
    REFUNDED: 'REFUNDED',
    PARTIAL_PAYMENT: 'PARTIAL_PAYMENT',
    DRAFT: 'DRAFT',
    ACCEPTED: 'ACCEPTED',
    DEPOSIT_PAID: 'DEPOSIT_PAID',
    ACCEPTED_INVOICED: 'ACCEPTED_INVOICED',
    INVOICED: 'INVOICED',
    DECLINED: 'DECLINED',
    UPDATED: 'UPDATED',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    COMPLETE: 'Complete',
};

export const frequencyStatus = {
    DAILY: '6',
    WEEKLY: '3',
    MONTHLY: '2',
    YEARLY: '1',
};

export const billingCycles = {
    DAILY: 6,
    WEEKLY: 3,
    MONTHLY: 2,
    YEARLY: 1,
};

export const recurringAttemptStatus = {
    APPROVED: 'APPROVED',
    FAILED: 'FAILED',
    ERROR: 'ERROR',
    PENDING: 'PENDING',
};

export const PAY_STATUS = {
    approved: 'APPROVED',
    manualPaid: 'MANUAL - PAID',
    paypalPaid: 'PAYPAL - PAID',
    refunded: 'Refunded',
};
