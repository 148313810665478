const PublicInvoicePage = () => import('./pages/PublicInvoicePage.vue');
const PayInvoicePage = () => import('./pages/PayInvoicePage.vue');
const SuccessPage = () => import('./pages/SuccessPage.vue');

const routes = [
    {
        path: '/:appId/:xid',
        name: 'invoice',
        component: PublicInvoicePage,
        meta: {
            title: 'public.invoice.viewTitle',
        },
    },
    {
        path: '/:appId/:xid/pay',
        name: 'invoice.pay',
        component: PayInvoicePage,
        meta: {
            title: 'public.invoice.payTitle',
        },
    },
    {
        path: '/:appId/:xid/success',
        name: 'invoice.success',
        component: SuccessPage,
        meta: {
            title: 'public.invoice.successTitle',
        },
    },
];

export default routes;
