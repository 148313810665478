const QuotePublicPage = () => import('./pages/QuotePublicPage.vue');
const AcceptedQuotePage = () => import('./pages/AcceptedQuotePage.vue');

const routes = [
    {
        path: '/:appId/:xid',
        name: 'quote',
        component: QuotePublicPage,
        meta: {
            title: 'public.quote.viewTitle',
        },
    },
    {
        path: '/:appId/:xid/accept',
        name: 'quote.accept',
        component: AcceptedQuotePage,
        meta: {
            title: 'public.quote.acceptTitle',
        },
    },
];

export default routes;
