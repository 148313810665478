import {
    fetchPublicCountryOptions,
    fetchPublicRegionOptions,
} from '../../api';

export default {
    LOAD_PUBLIC_BOOT(context, payload) {
        return loadPublicBoot(context, payload);
    },

    LOAD_PUBLIC_REGION_OPTIONS(context, payload) {
        return loadPublicRegionOptions(context, payload);
    },

    LOAD_PUBLIC_COUNTRY_OPTIONS(context, payload) {
        return loadPublicCountryOptions(context, payload);
    },
};

const loadPublicBoot = ({ commit }, { appId = '' }) => {
    if (window.__KEAP__.VUE_APP_APP_ID) {
        commit('SET_PUBLIC_CORE_APP_ID', window.__KEAP__.VUE_APP_APP_ID);
    }

    if (appId) {
        commit('SET_PUBLIC_CORE_APP_ID', appId);
    }
};

const loadPublicRegionOptions = async ({ commit }, { countryCode }) => {
    const { data } = await fetchPublicRegionOptions(countryCode);

    commit('SET_REGION_OPTIONS', data);

    return data;
};

const loadPublicCountryOptions = async ({ commit }) => {
    const { data } = await fetchPublicCountryOptions();

    commit('SET_COUNTRY_OPTIONS', data);

    return data;
};
