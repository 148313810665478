export default {
    title: '',
    appCountryCode: null,
    appCurrencyCode: 'USD',
    appLoaded: false,
    publicCoreAppId: '',
    isLocal: false,
    locale: null,
    overlaysActive: 0,
    rtl: false,
    regionOptions: [],
    countryOptions: [],
    timeZoneOptions: [],
    localeOptions: [],
    nestedModalStatus: {
        editModeEnabled: false,
        dirty: false,
    },
};
