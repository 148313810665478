import moment from 'moment';
import { orderStatus, frequencyStatus } from './money.constants';

export const sanitizeCardType = (cardType) => {
    return String(cardType)
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
};

export const detectCardType = (number) => {
    const cardPatterns = {
        'american-express': /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discovernovus: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    };

    const [detectedCardType] = Object.entries(cardPatterns)?.find(([cardType, pattern]) => (pattern.test(number) ? cardType : false)) || [];

    return detectedCardType;
};

export const getCardLogoPath = (cardType) => {
    const type = sanitizeCardType(cardType);

    if (['american-express', 'americanexpress'].includes(type)) return 'providers/amex.svg';
    if (['discovernovus', 'discover'].includes(type)) return 'providers/discover.svg';
    if (['mastercard', 'master'].includes(type)) return 'providers/mastercard.svg';
    if (['electron', 'visa'].includes(type)) return 'providers/visa.svg';
    if (type === 'diners-club') return 'providers/diners-club.svg';
    if (type === 'jcb') return 'providers/jcb.svg';
    if (type === 'maestro') return 'providers/Maestro.svg';

    return null;
};

export const isCardExpired = (card) => {
    const format = card?.expirationYear?.length === 2 ? 'YYMM' : 'YYYYMM';
    const expirationDate = moment(card.expirationYear + card.expirationMonth, format).add(1, 'months');

    return expirationDate.isValid() && moment().isAfter(expirationDate);
};

export const getProcessorLogo = (processorId) => {
    if (processorId === 'RAINFOREST') return 'keap-pay';
    if (processorId === 'AUTHORIZE') return 'authorize-net';
    if (processorId === 'PAYPAL') return 'paypal-logo';
    if (processorId === 'STRIPE') return 'stripe';
    if (processorId === 'NEXUS') return 'nexus-logo-card';
    if (processorId === 'E_WAY') return 'eway-logo';
    if (processorId === 'INF_PAY') return 'wepay-logo';

    return null;
};

export const getStatus = (status) => {
    switch (status) {
    case orderStatus.PAID:
        return 'paid';
    case orderStatus.DEPOSIT_PAID:
        return 'depositPaid';
    case orderStatus.UNPAID:
        return 'unpaid';
    case orderStatus.SENT:
        return 'sent';
    case orderStatus.VIEWED:
        return 'viewed';
    case orderStatus.REFUNDED:
        return 'refunded';
    case orderStatus.ACCEPTED:
        return 'accepted';
    case orderStatus.DECLINED:
        return 'declined';
    case orderStatus.UPDATED:
        return 'updated';
    case orderStatus.ACTIVE:
        return 'active';
    case orderStatus.INACTIVE:
        return 'inactive';
    case orderStatus.COMPLETE:
        return 'complete';
    default:
        return 'draft';
    }
};

export const getQuoteStatus = (status, orderId = 0) => {
    if (orderId && status === orderStatus.ACCEPTED) {
        return 'acceptedInvoiced';
    }

    if (orderId) {
        return 'invoiced';
    }

    return getStatus(status);
};

export const isCompletedPayment = (finalPaymentDate) => {
    return moment(finalPaymentDate).isBefore(moment());
};

export const getRecurringStatus = (status, finalPaymentDate) => {
    if (isCompletedPayment(finalPaymentDate)) {
        return getStatus(orderStatus.COMPLETE);
    }

    return getStatus(status);
};

export const getBillingCycleStatus = (status) => {
    switch (status) {
    case frequencyStatus.DAILY:
        return 'daily';
    case frequencyStatus.WEEKLY:
        return 'weekly';
    case frequencyStatus.MONTHLY:
        return 'monthly';
    case frequencyStatus.YEARLY:
        return 'yearly';
    default:
        return 'monthly';
    }
};

export const timeUnitStatus = (recurringFrequency) => {
    switch (recurringFrequency) {
    case frequencyStatus.DAILY:
        return 'days';
    case frequencyStatus.WEEKLY:
        return 'weeks';
    case frequencyStatus.MONTHLY:
        return 'months';
    case frequencyStatus.YEARLY:
        return 'years';
    default:
        return 'months';
    }
};

export const getPaymentType = (payStatus) => {
    let paymentType;

    switch (payStatus) {
    case 'PAYPAL - PAID':
        paymentType = 'MoneyLib.payments.paypalPayment';
        break;
    case 'APPROVED':
        paymentType = 'MoneyLib.payments.creditCardPayment';
        break;
    case 'Refunded':
        paymentType = 'MoneyLib.payments.refund';
        break;
    case 'PAYPAL - ADJUSTMENT':
        paymentType = 'MoneyLib.payments.paypalAdjustment';
        break;
    default:
        paymentType = 'MoneyLib.payments.manualPayment';
        break;
    }

    return paymentType;
};

export const invoiceIsRefunded = (invoice) => {
    return invoice && (invoice.status === orderStatus.REFUNDED || invoice.totalRefundAmount > 0);
};

export const invoiceTotalDue = (invoice) => {
    return !invoice || invoiceIsRefunded(invoice) ? 0 : invoice.total - invoice.totalPaid;
};
