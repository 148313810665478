import { SUPPORTED_LOCALES } from './supported-locales';

const getLocaleLanguage = (locale) => {
    return locale.split('-')[0];
};

const getSupportedLocale = (preferredLocale) => {
    if (SUPPORTED_LOCALES.includes(preferredLocale)) {
        return preferredLocale;
    }

    const fallbackLocale = getLocaleLanguage(preferredLocale);

    if (SUPPORTED_LOCALES.includes(fallbackLocale)) {
        return fallbackLocale;
    }

    return 'en-us';
};

const updateDesignSystemLocaleMessages = (Vue, i18n) => {
    const DESIGN_SYSTEM_MESSAGE_KEYS = [
        'modal.confirm.button',
        'modal.cancel.button',
        'date.format',
        'tablegrid.selected',
        'tablegrid.actions',
        'toggleswitch.on',
        'toggleswitch.off',
        'upload.label',
        'upload.complete.label',
        'upload.allowable',
        'upload.instructions',
        'select.add',
        'select.empty.results',
        'select.empty.options',
        'errorbanner.dismiss',
        'phone.input.label',
        'datetime.start.label',
        'datetime.starttime.label',
        'datetime.end.label',
        'datetime.endtime.label',
        'menu.loading',
    ];

    const messages = DESIGN_SYSTEM_MESSAGE_KEYS.reduce((acc, key) => {
        acc[key] = i18n.global.t(`ds.${key}`);

        return acc;
    }, {});

    Vue.prototype.$designSystem.i18n.setMessages(messages);
};

const getBrowserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const loadLocale = async (locale = '') => {
    if (!locale) {
        return {};
    }

    const {
        rtl = false,
    } = await import(/* webpackChunkName: "locale-[request]" */ `./locales/${locale}.js`);

    return { rtl };
};

export {
    getBrowserTimezone,
    getSupportedLocale,
    loadLocale,
    updateDesignSystemLocaleMessages,
};
