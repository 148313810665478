import axios from 'axios';

export default {
    PUBLIC_LOAD_QUOTE(context, payload) {
        return publicLoadQuote(context, payload);
    },

    PUBLIC_CREATE_INVOICE_PAYMENT_INTENT(context, invoiceId) {
        return publicCreateInvoicePaymentIntent(context, invoiceId);
    },

    PUBLIC_ACCEPT_QUOTE(context, payload) {
        return publicAcceptQuote(context, payload);
    },

    PUBLIC_LOAD_INVOICE(context, payload) {
        return publicLoadInvoice(context, payload);
    },

    PROCESS_PUBLIC_PAYMENT(context, paymentInfo) {
        return processPublicPayment(context, paymentInfo);
    },

    PUBLIC_SETUP_PAYPAL_BILLING_AGREEMENT(context, payload) {
        return publicSetupPaypalBillingAgreement(payload);
    },

    PUBLIC_SETUP_PAYPAL_ORDER(context, payload) {
        return publicSetupPaypalOrder(payload);
    },

    COMPLETE_AUTHORIZED_PAYMENT(context, authorizationDetails) {
        return completeAuthorizedPayment(context, authorizationDetails);
    },

    PUBLIC_UPDATE_MANUAL_INVOICE_PAYMENT_INTENT(context, data) {
        return updateManualInvoicePaymentPaymentIntent(context, data);
    },

    CREATE_RAINFOREST_PUBLIC_INVOICE_PAYMENT_INTENT(context, payload) {
        return createRainforestPublicInvoicePaymentIntent(context, payload);
    },

    PUBLIC_CREATE_PAYMENT_METHOD_INTENT(context, data) {
        return publicCreatePaymentMethodIntent(context, data);
    },

    SAVE_PUBLIC_PAYMENT_METHOD(context, paymentInfo) {
        return savePublicPaymentMethod(context, paymentInfo);
    },
};

const updateManualInvoicePaymentPaymentIntent = (context, { payload, paymentIntentId }) => {
    return axios.put(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/stripe/paymentIntent/${paymentIntentId}`, payload)
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

const publicLoadInvoice = ({ commit }, { xid }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${xid}`)
            .then(({ data }) => {
                commit('SET_PUBLIC_INVOICE', {
                    ...data.invoice,
                    id: data.invoice.orderId,
                    featureFlags: data.featureFlags,
                });
                commit('SET_APP_CURRENCY_CODE', data.appCurrencyCode, { root: true });
                commit('SET_APP_SALES_INFO', data.appSalesInfo);
                commit('SET_PAYPAL_MERCHANT', data.paypalMerchant);
                commit('SET_PAYMENT_SUBTYPE', data.paymentSubtype);
                commit('featureFlags/SET_FEATURE_FLAGS', data.featureFlags, { root: true });
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const publicAcceptQuote = ({ commit }, { xid }) => {
    return new Promise((resolve, reject) => {
        axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/quote/${xid}/accept`)
            .then(({ data }) => {
                commit('SET_PUBLIC_QUOTE', { quote: data.quote, companyProfile: data.companyProfile });
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const publicLoadQuote = ({ commit }, { xid }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/quote/${xid}`)
            .then(({ data }) => {
                commit('SET_PUBLIC_QUOTE', { quote: data.quote, companyProfile: data.companyProfile });
                commit('SET_APP_CURRENCY_CODE', data.appCurrencyCode, { root: true });
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const processPublicPayment = (_, paymentInfo) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${paymentInfo.invoiceXID}/acceptPayment`, paymentInfo)
        .then(({ data }) => {
            return data;
        });
};

const publicSetupPaypalBillingAgreement = ({ invoiceId }) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${invoiceId}/payPal/billingAgreements`)
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const publicCreateInvoicePaymentIntent = (context, invoiceId) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/order/${invoiceId}/stripe/paymentIntent`)
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

const publicSetupPaypalOrder = ({ invoiceId, amount }) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${invoiceId}/payPal/orders`, { payAmount: amount })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const completeAuthorizedPayment = (_, authorizationDetails) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${authorizationDetails.invoiceXID}/completeAuthorizedPayment`, authorizationDetails)
        .then(({ data }) => {
            return data;
        });
};

const createRainforestPublicInvoicePaymentIntent = async (context, payload) => {
    return axios.post(`${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/rainforest/paymentIntent`, payload)
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

const publicCreatePaymentMethodIntent = (context, { payload, appId }) => {
    const url = `${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/paymentMethods/intent`.replace('{id}', appId);

    return axios.post(url, payload)
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};

const savePublicPaymentMethod = (context, { payload, appId }) => {
    const url = `${window.__KEAP__.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/paymentMethods`.replace('{id}', appId);

    return axios.post(url, payload)
        .then(({ data }) => data)
        .catch((e) => {
            throw e;
        });
};
