// For use when searching data from an input
export const SEARCHING_DEBOUNCE_DELAY = 300;

// For use with general inputs
export const INPUT_DEBOUNCE_DELAY = 500;

// Used when loading data such as tables from an input
export const LOADING_DEBOUNCE_DELAY = 1000;

// Used for autosaving
export const SAVING_DEBOUNCE_DELAY = 1200;

// Used for autocompleting such as addresses, important to have a high delay to keep costs low
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 1500;

// Used for delaying blur events
export const BLUR_DELAY = 300;

// Used for animation flashing
export const FLASH_DELAY = 1200;

// Used for remove Copied text
export const COPY_TEXT_AUTOHIDE_DELAY = 3000;

// Used to delay based on the animation of the nav
export const NAV_ANIMATION_DELAY = 200;
