export const RAINFOREST_BASE_SETTINGS = {
    'style-font-family': 'Sul Sans, Helvetica, Arial, sans-serif',
    'style-font-size': '1rem',
    'style-border-color': '#cccccc',
    'style-border-radius': '0.5rem',
    'style-border-width': '1px',
    'style-button-color': '#006ceb',
    'style-button-border-radius': '0.5rem',
    'style-error-border-color': '#e02500',
    'style-error-message-color': '#e02500',
    'style-icon-color': 'rgba(0, 0, 0, 0.824)',
    'style-list-alternate-row-color': '#FAFAFA',
    'style-table-color': 'rgba(0, 0, 0, 60.4)',
};

export const RAINFOREST_MERCHANT_ONBOARDING_SETTINGS = {
    ...RAINFOREST_BASE_SETTINGS,
    'style-placeholder-text': true,
    'terms-and-conditions-url': 'https://keap.com/legal/terms-of-service',
};

export const RAINFOREST_PAYMENT_COMPONENT_SETTINGS = {
    ...RAINFOREST_BASE_SETTINGS,
    'style-font-size': '0.875rem',
    'allowed-methods': 'CARD',
    'hide-button': true,
};

export const RAINFOREST_PAYMENT_REPORT_SETTINGS = {
    ...RAINFOREST_BASE_SETTINGS,
    'display-records': '12',
    'display-header': ' ',
    'hide-actions': true,
    'style-font-size': '1rem',
    'style-button-color': '#656565',
    'style-button-border-radius': '0.5rem',
    'style-table-font-size': '0.875rem',
};

export const RAINFOREST_DEPOSIT_REPORT_SETTINGS = {
    ...RAINFOREST_BASE_SETTINGS,
    'display-records': '12',
    'display-header': ' ',
    'hide-actions': true,
    'hide-name': false,
    'hide-method': false,
    'hide-date': false,
    'hide-customer-address': false,
    'hide-related-info': false,
    'style-font-size': '0.875rem',
    'style-button-color': 'rgba(0, 0, 0, 0.4)',
    'style-table-font-size': '0.875rem',
};

export const RAINFOREST_MERCHANT_STATUSES = {
    ONBOARDING: 'ONBOARDING',
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
};
