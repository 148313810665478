import moment from 'moment';

import { i18nMessages } from '@keap-web/money-lib';
import defaultMessages from './en-us.json';
import { sharedUiMessages } from '@keap-web/shared-ui';

import {
    createI18n,
    getSupportedLocale,
} from '@keap-web/keap-i18n';

const i18n = createI18n({
    messages: {
        'en-us': {
            ...i18nMessages,
            ...defaultMessages,
            ...sharedUiMessages,
        },
    },
});

const loadLocale = async () => {
    const locale = getSupportedLocale(navigator.language.toLowerCase());

    if (locale !== 'en-us') {
        const { messages, rtl } = await import(`./locales/${locale}.js`);

        i18n.global.locale = locale;
        moment.locale(locale);

        if (messages) {
            i18n.global.setLocaleMessage(locale, messages);
        }

        if (rtl) {
            document.documentElement.setAttribute('dir', 'rtl');
        }
    }

    return locale;
};

export { i18n, loadLocale };
