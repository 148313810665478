const combineNames = (...args) => args.filter((x) => x).join(' ');

const getContactFullName = (contact) => {
    if (!contact) {
        return '';
    }

    const {
        firstName,
        lastName,
        givenName,
        familyName,
    } = contact;

    return combineNames(firstName, lastName) || combineNames(givenName, familyName);
};

const getContactDisplayName = (contact) => {
    if (!contact) {
        return '';
    }

    const { email, emailAddresses } = contact;

    const fullName = getContactFullName(contact);

    if (fullName) {
        return fullName;
    }

    if (emailAddresses && emailAddresses.length) {
        return emailAddresses[0].email;
    }

    if (email) {
        return email;
    }

    return '';
};

export {
    getContactFullName,
    getContactDisplayName,
};
