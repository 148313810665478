import { createRouter as createVueRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import { onRoute } from './utils/router.util';

export const createRouter = (store) => {
    const router = createVueRouter({
        history: createWebHistory(),
        routes,
    });

    router.beforeResolve((to, from, next) => {
        return onRoute(to, from, next, store);
    });

    return router;
};
