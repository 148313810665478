const PageNotFound = () => import('../pages/PageNotFound.vue');
const PaymentAuthenticationReturnPage = () => import('../money/pages/PaymentAuthenticationReturnPage.vue');

import quoteRoutes from '../money/quote.routes';
import invoiceRoutes from '../money/invoice.routes';
import paymentsRoutes from '../money/payments.routes';
import DOMAINS from '../constants/domains.constants';

const routes = [];
const { host, search } = window.location;
const domains = host.split('.');
const subdomain = domains[0];

// domain-specific routes
if (subdomain.includes(DOMAINS.QUOTE)) {
    routes.push(...quoteRoutes);
}

if (subdomain.includes(DOMAINS.INVOICE)) {
    routes.push(...invoiceRoutes);
}

if (subdomain.includes(DOMAINS.PAYMENTS)) {
    routes.push(...paymentsRoutes);
}

if (subdomain.includes('localhost')) {
    const type = search.split('=')[1];

    switch (type) {
    case 'quote':
        routes.push(...quoteRoutes);
        break;

    case 'invoice':
        routes.push(...invoiceRoutes);
        break;

    case 'payments':
        routes.push(...paymentsRoutes);
        break;

    default:
        break;
    }
}

routes.push(
    {
        path: '/page-not-found',
        name: 'page.not.found',
        component: PageNotFound,
        meta: {
            title: 'public.notFound',
            hideNav: true,
        },
    },
    {
        path: '/payment/auth/return',
        name: 'payment.auth.return',
        component: PaymentAuthenticationReturnPage,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/page-not-found',
    },
);

export default routes;
