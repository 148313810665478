export default {
    SET_PUBLIC_QUOTE(state, publicQuote) {
        state.publicQuote = publicQuote;
    },

    SET_PAYPAL_MERCHANT(state, payload) {
        state.paypalMerchant = payload;
    },

    SET_PAYMENT_SUBTYPE(state, payload) {
        state.paymentSubtype = payload;
    },

    SET_APP_SALES_INFO(state, payload) {
        state.appSalesInfo = payload;
    },

    SET_PUBLIC_INVOICE(state, payload) {
        state.publicInvoice = payload;
    },

    SET_INVOICE(state, payload) {
        state.invoice = payload;
    },

    SET_PROCESSING(state, processing) {
        state.processing = processing;
    },
};
