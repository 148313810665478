import { createStore as createVuexStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import featureFlags from './featureFlags';
import global from './global';
import sales from '../money/store';

export const modules = {
    featureFlags,
    global,
    sales,
};

const vuexPersistPathsAllowList = [
    'global.locale',
];

const localStorageKey = window.__KEAP__.VUE_APP_ENV !== 'production'
    ? `keap-web-${window.__KEAP__.VUE_APP_ENV}`
    : 'keap-web';

export const createStore = () => {
    const store = createVuexStore({
        modules,
        plugins: [
            createPersistedState({
                key: localStorageKey,
                paths: vuexPersistPathsAllowList,
            }),
        ],
    });

    return store;
};
