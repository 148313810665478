<template>
    <div class="stripe-elements">
        <DsInlineAlert
            v-if="error"
            leading-icon
            type="critical"
        >
            {{ $t('MoneyLib.stripe.error') }}
        </DsInlineAlert>

        <div v-else-if="!stripe" class="spinner-wrapper">
            <DsSpinner />
        </div>

        <div id="payment-element" class="fs-block" />
    </div>
</template>

<script>
/* eslint-disable no-underscore-dangle, camelcase */
import { INPUT_DEBOUNCE_DELAY } from '@keap-web/shared-ui';
import { mapState } from 'vuex';
import { DsInlineAlert, DsSpinner } from '@infusionsoft/design-system';
import useCreditCardFields from './useCreditCardFields';

export default {
    name: 'StripeElements',

    components: {
        DsInlineAlert,
        DsSpinner,
    },

    props: {
        checkoutForm: Object,
        publicCheckoutForm: Object,
        manualPayment: [Object, Number],
        readonly: Boolean,
    },

    setup() {
        const { onStripeProcessedAnalytics, stripeElementsUrl } = useCreditCardFields();

        return { onStripeProcessedAnalytics, stripeElementsUrl };
    },

    data() {
        return {
            stripe: null,
            error: false,
            elements: null,
            paymentIntent: null,
            setupIntent: null,
            loadInterval: null,
            appearance: {
                theme: 'flat',
                labels: 'floating',
                rules: {
                    '.Input': {
                        border: '1px solid #ccc',
                    },
                    '.Label--resting': {
                        color: 'rgba(0, 0, 0, 0.6)',
                    },
                    '.Label--floating': {
                        color: '#0042db',
                    },
                    '.Input:focus': {
                        outline: 'none',
                        border: '1px solid #0042db',
                    },
                },
                variables: {
                    fontFamily: 'Sul Sans, Helvetica, Arial, sans-serif',
                    colorBackground: '#fff',
                    colorDanger: '#E02500',
                    colorIcon: 'rgba(0, 0, 0, 0.4)',
                    borderRadius: '.5rem',
                    fontSizeSm: '14px',
                    fontSize3Xs: '12px',
                    spacingGridRow: '1rem',
                    spacingUnit: '4px',
                },
            },
        };
    },

    computed: {
        ...mapState({
            checkoutForms: ({ sales }) => sales.checkoutForms,
            stripePreviewPaymentIntent: ({ sales }) => sales.stripePreviewPaymentIntent,
            checkoutDiscounts: ({ sales }) => sales.checkoutDiscounts,
            paymentAccounts: ({ billing }) => billing?.paymentAccounts,
            selectedUpsell: ({ sales }) => sales.selectedUpsell,
            appliedPromoCodes: ({ sales }) => sales.appliedPromoCodes,
            checkoutFormData: ({ sales }) => sales.checkoutFormData,
            publicInvoice: ({ sales }) => sales.publicInvoice,
            appSalesInfo: ({ sales }) => sales.appSalesInfo,
        }),

        isCheckoutForm() {
            return Boolean(this.publicCheckoutForm?.checkoutForm?.checkoutFormUrl);
        },

        checkoutFormTotal() {
            const products = this.publicCheckoutForm?.checkoutForm?.checkoutFormItemList;
            const taxTotal = products?.filter(({ taxTemplates }) => Boolean(taxTemplates))
                ?.reduce((total, product) => {
                    const productTotalTaxes = product?.taxTemplates?.reduce((productTotal, tax) => productTotal + parseFloat(tax.amount), 0);

                    return total + productTotalTaxes;
                }, 0);

            const subTotal = products?.reduce((sum, { productPrice }) => sum + parseFloat(productPrice || 0), 0);

            return taxTotal + subTotal;
        },

        actionPayload() {
            if (this.isCheckoutForm) return this.publicCheckoutForm.checkoutForm.checkoutFormUrl;
            if (this.publicInvoice?.orderId) return this.publicInvoice.orderId;
            if (this.manualPayment) return this.manualPayment;

            return null;
        },

        initializeAction() {
            if (this.readonly) return 'sales/CREATE_CHECKOUT_FORM_PREVIEW_PAYMENT_INTENT';

            if (this.isCheckoutForm) {
                return this.isCheckoutFormZeroTotal
                    ? 'sales/CREATE_CHECKOUT_FORM_SETUP_INTENT'
                    : 'sales/CREATE_CHECKOUT_FORM_PAYMENT_INTENT';
            }

            if (this.publicInvoice?.orderId) return 'sales/PUBLIC_CREATE_INVOICE_PAYMENT_INTENT';
            if (this.manualPayment?.orderItems) return 'sales/CREATE_MANUAL_PAYMENT_PAYMENT_INTENT';
            if (this.manualPayment) return 'sales/CREATE_MANUAL_INVOICE_PAYMENT_PAYMENT_INTENT';

            return null;
        },

        redirectClientSecret() {
            return this.$route.query?.payment_intent_client_secret;
        },

        isCheckoutFormZeroTotal() {
            if (!this.isCheckoutForm) return false;

            return this.checkoutFormTotal === 0 || this.checkoutDiscounts?.discountedAmount === 0;
        },
    },

    watch: {
        selectedUpsell() { this.updatePaymentIntent(); },

        appliedPromoCodes: {
            handler(newValue, oldValue) {
                const removedPromoCode = newValue === null && oldValue;

                if (removedPromoCode && !this.isCheckoutFormZeroTotal) this.initialize();

                this.updatePaymentIntent(removedPromoCode);
            },
            deep: true,
        },
    },

    mounted() {
        this.$bus?.$on('SUBMIT_STRIPE_ELEMENTS_FORM', this.submit);
        this.$bus?.$on('UPDATE_INVOICE_PAYMENT', this.updateInvoicePaymentIntent);
        this.$bus?.$on('UPDATE_MANUAL_PAYMENT', this.updateManualPaymentIntent);
        this.$bus?.$on('UPDATE_PUBLIC_INVOICE_PAYMENT', this.updatePaymentPublicInvoiceIntent);

        if (this.redirectClientSecret) this.$store.commit('sales/SET_PROCESSING', true);

        this.loadStripe();
    },

    beforeUnmount() {
        this.clearInterval();
        this.$bus.$off('SUBMIT_STRIPE_ELEMENTS_FORM');
        this.$bus.$off('UPDATE_INVOICE_PAYMENT');
        this.$bus.$off('UPDATE_MANUAL_PAYMENT');
        this.$bus.$off('UPDATE_PUBLIC_INVOICE_PAYMENT');
    },

    methods: {
        loadStripe() {
            const stripeScript = document.createElement('script');

            stripeScript.setAttribute('id', 'stripe');
            stripeScript.setAttribute('src', this.stripeElementsUrl);

            document.head.appendChild(stripeScript);

            this.loadInterval = setInterval(() => {
                if (!window.Stripe) return;

                this.clearInterval();
                this.initialize();
            }, INPUT_DEBOUNCE_DELAY);
        },

        clearInterval() {
            if (this.loadInterval) {
                clearInterval(this.loadInterval);
                this.loadInterval = null;
            }
        },

        async initialize() {
            const { accountId, clientSecret } = this.readonly && this.stripePreviewPaymentIntent
                ? this.stripePreviewPaymentIntent
                : await this.$store.dispatch(this.initializeAction, this.actionPayload)
                    .catch(() => {
                        this.error = true;
                        this.$bus.$emit('STRIPE_LOAD_ERROR');
                    });

            const { appearance } = this;
            const testModeEnabled = this.publicCheckoutForm?.appSalesInfo?.stripeTestMode || this.appSalesInfo?.stripeTestMode;

            const stripeKey = testModeEnabled
                ? window.__KEAP__.VUE_APP_STRIPE_ELEMENTS_PUBLIC_TEST_KEY
                : window.__KEAP__.VUE_APP_STRIPE_ELEMENTS_PUBLIC_KEY;

            this.stripe = window.Stripe(stripeKey, { stripeAccount: accountId });

            this.elements = this.stripe.elements({ clientSecret, appearance });
            const paymentElement = this.elements.create('payment');

            const secret = this.redirectClientSecret || clientSecret;

            if (this.isCheckoutFormZeroTotal) {
                const { setupIntent } = await this.stripe.retrieveSetupIntent(secret);

                this.setupIntent = setupIntent;
            } else {
                const { paymentIntent } = await this.stripe.retrievePaymentIntent(secret);

                this.paymentIntent = paymentIntent;
            }

            this.$bus.$emit('PAYMENT_INTENT_CREATED');

            if (this.redirectClientSecret) {
                this.handleRedirect();
            } else {
                paymentElement.mount('#payment-element');
                this.$bus.$emit('STRIPE_ELEMENTS_MOUNTED');
            }
        },

        async updatePaymentIntent() {
            if (this.isCheckoutFormZeroTotal) {
                this.initialize();

                return;
            }

            const paymentIntentId = this.paymentIntent?.id;
            const upsellIds = this.selectedUpsell?.upsellId
                ? [this.selectedUpsell.upsellId]
                : [];

            const promoCodes = this.appliedPromoCodes
                ? [this.appliedPromoCodes]
                : [];

            const payload = {
                upsellIds,
                promoCodes,
                paymentIntentId,
            };

            const checkoutFormSlug = this.publicCheckoutForm?.checkoutForm.checkoutFormUrl;

            await this.$store.dispatch('sales/PUBLIC_UPDATE_CHECKOUT_FORM', { payload, checkoutFormSlug });
        },

        updateInvoicePaymentIntent({ orderId, amount }) {
            this.$store.dispatch('sales/UPDATE_MANUAL_INVOICE_PAYMENT_INTENT', {
                payload: { amount: amount?.toFixed(2) },
                paymentIntentId: this.paymentIntent.id,
                orderId,
            });
        },

        updateManualPaymentIntent({ orderItems }) {
            this.$store.dispatch('sales/UPDATE_MANUAL_PAYMENT_PAYMENT_INTENT', {
                payload: { amount: orderItems?.[0]?.price?.toFixed(2) },
                paymentIntentId: this.paymentIntent.id,
            });
        },

        updatePaymentPublicInvoiceIntent({ orderId, amount }) {
            this.$store.dispatch('sales/PUBLIC_UPDATE_MANUAL_INVOICE_PAYMENT_INTENT', {
                payload: { amount: amount?.toFixed(2) },
                paymentIntentId: this.paymentIntent.id,
                orderId,
            });
        },

        async submit() {
            this.$store.commit('sales/SET_PROCESSING', true);

            const { elements } = this;
            const intentOptions = {
                elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: `${window.location}`,
                },
            };

            const successfulStatuses = ['succeeded', 'processing'];

            if (this.isCheckoutFormZeroTotal) {
                const { error, setupIntent } = await this.stripe.confirmSetup(intentOptions);

                if (error) this.handlePaymentError(error?.message);

                if (setupIntent?.id && successfulStatuses.includes(setupIntent?.status)) {
                    this.$bus.$emit('STRIPE_ELEMENTS_SUCCESSFUL_PAYMENT', setupIntent?.id);
                }
            } else {
                const { error, paymentIntent } = await this.stripe.confirmPayment(intentOptions);

                if (error) this.handlePaymentError(error?.message);

                if (paymentIntent?.id && successfulStatuses.includes(paymentIntent?.status)) {
                    this.$bus.$emit('STRIPE_ELEMENTS_SUCCESSFUL_PAYMENT', paymentIntent?.id);
                }
            }
        },

        handlePaymentError(message) {
            this.$error({ message, bottom: true });
            this.$bus.$emit('STRIPE_ELEMENTS_FAILED_PAYMENT');
            this.$store.commit('sales/SET_PROCESSING', false);
        },

        async handleRedirect() {
            const {
                email,
                nameOnCard,
                promoCodes,
                upsells,
                payAmount,
            } = this.checkoutFormData;

            if (!email || !nameOnCard) {
                this.$router.replace({ query: {} });
                this.$store.commit('sales/SET_PROCESSING', false);
                this.initialize();

                return;
            }

            const fullName = nameOnCard?.split(' ');
            const [firstName] = fullName;
            const lastName = fullName?.splice(1).join(' ');

            this.onStripeProcessedAnalytics();

            const { paymentIntent } = await this.stripe.retrievePaymentIntent(this.redirectClientSecret);

            const payload = {
                email,
                nameOnCard,
                firstName,
                lastName,
                paymentType: 'token',
                paymentToken: paymentIntent.id,
                tokenProcessor: 'stripe',
                checkoutFormIdentifier: this.publicCheckoutForm.checkoutForm.checkoutFormUrl,
                upsells,
                promoCodes,
                payAmount,
            };

            try {
                await this.$store.dispatch('sales/PROCESS_CHECKOUT_PAYMENT', payload);

                this.$router.replace({ query: {} });
                this.$bus.$emit('CHECKOUT_FORM_PAYMENT_MADE');
            } catch (e) {
                this.$error({ message: this.$t('billing.paymentError.default'), bottom: true });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.stripe-elements {
    padding-bottom: $spacing-200;
}

.spinner-wrapper {
    text-align: center;
}
</style>
