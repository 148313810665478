import sentry from '../../analytics/sentry';
import { i18n, loadLocale } from '../../i18n';

export const onRoute = async (to, _, next, store) => {
    try {
        await loadLocale();
    } catch (e) {
        sentry.captureException(e);
    }

    handlePublicRoute(to, store);

    if (!routeIsValid(to, store)) {
        return next('/page-not-found');
    }

    store.commit('SET_APP_LOADED');
    setTitle(to, store);

    return next();
};

const handlePublicRoute = (to, store) => {
    const appId = to.query.app_id || to.params.appId;

    store.dispatch('LOAD_PUBLIC_BOOT', { appId });
};

const routeHasRequiredFeatureFlag = (route, store) => {
    const { featureFlags } = store.state;
    const { featureFlag } = route.meta;

    return Boolean(!featureFlag || (featureFlags && featureFlags[featureFlag]));
};

const routeIsValid = (to, store) => {
    return to.matched.every((route) => {
        return routeHasRequiredFeatureFlag(route, store);
    });
};

const setTitle = (route, store) => {
    const baseTitle = i18n.t(route.meta.title || 'global.title', { productName: i18n.t('global.productName') });

    setTimeout(() => {
        store.commit('SET_TITLE', baseTitle);
        document.title = baseTitle;
    });
};
