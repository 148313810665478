import { inject, onMounted, onBeforeUnmount } from 'vue';
import mitt from 'mitt';

export function createBus() {
    const emitter = mitt();
    const { on, off, emit } = emitter;

    const bus = {
        ...emitter,
        $on: on,
        $off: off,
        $emit: emit,
    };

    bus.install = (app) => {
        app.provide('bus', bus);
        app.config.globalProperties.$bus = bus;
    };

    return bus;
}

export function useBus() {
    return inject('bus');
}

export function useBusEventListener(event, callback) {
    const bus = useBus();

    onMounted(() => {
        bus.on(event, callback);
    });

    onBeforeUnmount(() => {
        bus.off(event, callback);
    });
}
