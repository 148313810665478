import { inject, unref } from 'vue';

function loadScript(id, src) {
    if (document.getElementById(id)) return;

    const script = document.createElement('script');

    script.setAttribute('src', src);
    script.setAttribute('type', 'module');
    script.setAttribute('id', id);

    document.head.appendChild(script);
}

export function useRainforest() {
    return inject('rainforest', {});
}

export function useRainforestPaymentScript() {
    const { paymentSrc } = useRainforest();
    const scriptId = 'rainforest-payment';

    loadScript(scriptId, unref(paymentSrc));
}

export function useRainforestMerchantScript() {
    const { merchantSrc } = useRainforest();
    const scriptId = 'rainforest-merchant';

    loadScript(scriptId, unref(merchantSrc));
}
