import { inject } from 'vue';

export default function useCreditCardFields() {
    const {
        stripeElementsEnabled = false,
        stripeElementsUrl = '',
        onStripeProcessedAnalytics = () => {},
    } = inject('creditCardFieldsContext', {});

    return {
        stripeElementsEnabled,
        stripeElementsUrl,
        onStripeProcessedAnalytics,
    };
}
