import axios from 'axios';

export function setupInterceptors(store) {
    axios.interceptors.request.use((config) => {
        const coreAppId = store.state.global.publicCoreAppId;

        const callingCoreApp = config.url.includes(window.__KEAP__.VUE_APP_CORE_URL_PATTERN);

        if (callingCoreApp) {
            config.url = config.url.replace('{id}', coreAppId);
            config.withCredentials = true;
        }

        return config;
    });
}
