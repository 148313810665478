import { numberFormats } from '@keap-web/keap-i18n';
import { i18n } from '../../i18n';

const mapToArray = (map) => {
    return Object.keys(map).map((key) => {
        return { value: key, label: map[key] };
    });
};

export default {
    SET_TITLE(state, title) {
        state.title = title || i18n.t('global.productName');
    },

    SET_LOCALE(state, locale) {
        state.locale = locale;
    },

    SET_OVERLAY_ACTIVE(state, active) {
        if (active) {
            state.overlaysActive++;
        } else if (state.overlaysActive > 0) {
            state.overlaysActive--;
        }
    },

    SET_APP_COUNTRY_CODE(state, appCountryCode) {
        state.appCountryCode = appCountryCode;
    },

    SET_APP_LOADED(state, loaded = true) {
        state.appLoaded = loaded;
    },

    SET_BOOT_LOAD_ERROR(state, error) {
        state.bootLoadError = error;
    },

    SET_DEFERRED_BOOT_LOADED(state, loaded = true) {
        state.deferredBootLoaded = loaded;
    },

    SET_IS_LOCAL(state) {
        state.isLocal = true;
    },

    SET_COUNTRY_OPTIONS(state, countryOptions) {
        state.countryOptions = mapToArray(countryOptions);
    },

    SET_PUBLIC_CORE_APP_ID(state, appId) {
        state.publicCoreAppId = appId;
    },

    SET_REGION_OPTIONS(state, regionOptions) {
        state.regionOptions = mapToArray(regionOptions);
    },

    SET_TIME_ZONE_OPTIONS(state, timeZoneOptions) {
        state.timeZoneOptions = mapToArray(timeZoneOptions);
    },

    SET_LOCALE_OPTIONS(state, localeOptions) {
        state.localeOptions = mapToArray(localeOptions);
    },

    SET_APP_CURRENCY_CODE(state, appCurrencyCode) {
        state.appCurrencyCode = appCurrencyCode;
        updateAppCurrency(state, appCurrencyCode);
    },

    SET_NESTED_MODAL_EDIT_MODE_ENABLED(state, editModeEnabled) {
        state.nestedModalStatus.editModeEnabled = editModeEnabled;
    },

    SET_NESTED_MODAL_DIRTY(state, dirty) {
        state.nestedModalStatus.dirty = dirty;
    },
};

const updateAppCurrency = (_, currency) => {
    if (typeof currency === 'string') {
        for (const localeName in numberFormats) {
            if (Object.prototype.hasOwnProperty.call(numberFormats, localeName)) {
                const locale = numberFormats[localeName];

                for (const optionName in locale) {
                    if (Object.prototype.hasOwnProperty.call(locale, optionName)) {
                        const option = locale[optionName];

                        if (Object.prototype.hasOwnProperty.call(option, 'currency')) {
                            option.currency = currency;
                        }
                    }
                }

                i18n.global.setNumberFormat(localeName, locale);
            }
        }
    }
};
